import { request} from "./request";

export function getInfo(address) {
  return request({
    url: '/query',
    method: 'post',
    params: {
      'address': address,
      'timeout': 2,
    }
  })
}

export function sendInfo(address, body, time) {
  return request({
    url: '/send',
    method: 'post',
    params: {
      'address': address,
      'body': body,
      'time': time,
    }
  })
}