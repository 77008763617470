import Vue from 'vue'
import App from './App.vue'

import 'vant/lib/index.css';
import {Form, Field, Button, Switch, Toast, Popup, Picker} from 'vant'
Vue.use(Form)
Vue.use(Field)
Vue.use(Button)
Vue.use(Switch)
Vue.use(Popup)
Vue.use(Picker)
Vue.use(Toast)


Vue.config.productionTip = false

new Vue({
  components: {
    App
  },
  render: h => h(App)
}).$mount('#app')
