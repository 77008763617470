<template>
  <div id="app">
    <h3>热疗仪开机测试版</h3>
    <van-form @submit="onSubmit">
      <van-field
              v-model="device"
              name="device"
              label="热疗仪编号："
              placeholder="请输入热疗仪编号"
              :rules="[{ required: true}]"
      />

      <van-field
          v-model="time"
          name="time"
          label="疗程时间："
          placeholder="15"
          :rules="[{ required: true}]"
      />

      <van-field
          readonly
          clickable
          name="body"
          :value="value"
          label="理疗部位："
          placeholder="点击选择部位"
          @click="showPicker = true"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="showPicker = false"
        />
      </van-popup>


      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {getInfo, sendInfo} from "./network/app";

export default {
    data() {
      return {
        device: '',
        time: 15,
        body: '',
        value: '胸部',
        columns: ['胸部', '背部', '臀部', '膝盖'],
        showPicker: false,
      };
    },
    methods: {
      onConfirm(value) {
        this.value = value;
        this.showPicker = false;
      },

      onSubmit(values) {
        if (this.device.length != 8 ) {
          this.$toast('请输入正确的8位设备编号！');
        } else {
          console.log('submit', values);
          getInfo(this.device).then(res => {  //下发查询
            console.log('下发查询指令返回结果:', res);
            if(res.state === '0005'){  //待机或二维码状态时下发开机指令
              switch (this.value){
                case "背部":
                  this.body = '2'
                  break;
                case "臀部":
                  this.body = '4'
                  break;
                case "膝盖":
                  this.body = '5'
                  break;
                default:
                  this.body = '1'
              }
                sendInfo(this.device, this.body, this.time).then(res => {
                  console.log('下发开机指令返回结果：', res);
                  if (res.state === '0004') {
                    this.$toast('开机成功！');
                  } else {
                    this.$toast('不能重复开机！')
                  }
                }).catch(err => {
                  this.$toast('连接超时，请检查设备联机情况！')
                })

            } else {
              this.$toast('热疗仪非待机状态或不在线！')
              console.log('热疗仪状态是：', res.error);
            }
          }).catch(err => {
            this.$toast('连接超时，请检查设备联机情况！')})
          }
        }

      },
  };
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
